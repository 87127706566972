import React, { Component } from 'react';

import 'babel-polyfill'

import { Form, Icon, Input, Button, Card, Modal } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import "../styles/css/login.scss";
import { login,getRegisterCode,loginCode,register ,forgetPassword,getForgetCode} from "./api/categories";
class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            hospital: '',
            password: '',
            phone: '',
            smsCode: '',
			timeLeft: 60,
			     isCountingDown: false,
        };
    }
	validatePhoneNumber=(phone)=> {
	    // 正则表达式：以1开头，后面跟着10个数字
	    const phoneRegex = /^1[3-9]\d{9}$/;
	    return phoneRegex.test(phone);
	}
	getLoginCode = async (phone) => {
		  if (this.validatePhoneNumber(phone)) {
		      console.log("手机号格式正确");
			  if (!this.state.isCountingDown) {
			        this.startTimer();
					try {
					       // 使用 GET 请求，传递参数
					       const res = await getForgetCode({
					           phone: phone,
					       });
								
					       console.log(res);
					       if (res.code === "200") {
					           
					       } else {
					           alert(res.msg);
					       }
					   } catch (error) {
					       console.error("请求失败:", error);
					       alert("请求失败，请稍后再试");
					   }
					
					
					}
		  } else {
		      alert("手机号输入错误");
		  }
			 
	
	  
	};
	// handleSmsLogin = (e) => {
	//     e.preventDefault();
	//     // 在这里可以进行表单验证和提交逻辑
	//     console.log('注册信息:', this.state);
	//     // 例如，调用 API 提交注册信息
	// };
	handleSmsLogin = async (e) => {
	 e.preventDefault();
	     // 在这里可以进行表单验证和提交逻辑
	     console.log('注册信息:', this.state);
	 const { userName, password, phone, smsCode,hospital } = this.state;

				try {
				       // 使用 GET 请求，传递参数
				       const res = await forgetPassword({
				                         password: password,
				                         phone: phone,
				                         code: smsCode,
				       });
						
				       console.log(res);
				       if (res.code === "200") {
				           alert("密码更新成功");
				           this.props.history.push("/");
				       } else {
				           alert(res.msg);
				       }
				   } catch (error) {
				       console.error("请求失败:", error);
				       alert(res.msg);
				   }
	};
    inputChangeUserName = (e) => {
        this.setState({ userName: e.target.value });
    };

    inputChangeHospital = (e) => {
        this.setState({ hospital: e.target.value });
    };

    inputChangePassword = (e) => {
        this.setState({ password: e.target.value });
    };

    inputChangePhoneNumber = (e) => {
        this.setState({ phone: e.target.value });
    };

    inputChangeSmsCode = (e) => {
        this.setState({ smsCode: e.target.value });
    };

  startTimer = () => {
      this.setState({ isCountingDown: true });
      this.countdown = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.timeLeft > 0) {
            return { timeLeft: prevState.timeLeft - 1 };
          } else {
            clearInterval(this.countdown);
            return { isCountingDown: false };
          }
        });
      }, 1000);
    }

    

    render() {
        const { userName, hospital, password, phone, smsCode } = this.state;
 const { timeLeft, isCountingDown } = this.state;
		    const buttonText = isCountingDown ? `${timeLeft}秒后重试` : '获取验证码';
        return (
		<div className="login-main">
		    <div className="login-bg"  >
			
		    <div style={{    fontSize: "33px",width:"500px",marginLeft:"5%",textAlign:"center"}}>
		       
            <Form
                onSubmit={this.handleSmsLogin}
                className="login-form"
                style={{ padding: "20px", marginLeft: "10px" }}
            >
                <h1 className="login-title">重置密码</h1>

              
				<Form.Item
				    name="phoneNumber"
				    rules={[{ required: true, message: "请输入手机号码" }]}
				>	<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                	<div style={{width:"150px"}}>手机号码:</div>	
				    <Input
				        onChange={this.inputChangePhoneNumber}
				        prefix={<Icon type="phone" className="login-input-icon" />}
				        placeholder="手机号码"
				    /></div>	
				</Form.Item>
               
			
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "请输入密码" }]}
                ><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                	<div style={{width:"150px"}}>新密码:</div>
                    <Input
                        type="password"
                        onChange={this.inputChangePassword}
                        prefix={<Icon type="lock" className="login-input-icon" />}
                        placeholder="新密码"
                    /></div>
                </Form.Item>

               <Form.Item
                   name="password"
                   rules={[{ required: true, message: "请输入密码" }]}
               ><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                	<div style={{width:"150px"}}>重复输入新密码:</div>
                   <Input
                       type="password"
                     
                       prefix={<Icon type="lock" className="login-input-icon" />}
                       placeholder="新密码"
                   /></div>
               </Form.Item>

                <Form.Item
                    name="smsCode"
                    rules={[{ required: true, message: "请输入验证码" }]}
                ><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                	<div style={{width:"150px"}}>验证码:</div>	
                    <div style={{ width:"100%" ,marginLeft:"-40px"}}  >
                			<Input
                			style={{ width:"73%" }}
                			    onChange={this.inputChangeSmsCode}
                			    prefix={<Icon type="key" className="login-input-icon" />}
                			    placeholder="验证码"
                			/></div>
                				<Button
                				        style={{ width: "20%" }}
                				        type="primary"
                				        className="login-join-button"
                				        onClick={() => this.getLoginCode(phone)}
                				      >
                				        {buttonText}
                				      </Button>
                	</div>
                </Form.Item>
				
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-join-button"
                    >
                        重置密码
                    </Button>
                </Form.Item>
            </Form>
			</div>
			</div>
			</div>
        );
    }
}

export default ForgetPassword;
