import React, { Component } from 'react';

import 'babel-polyfill'

import { Form, Icon, Input, Button, Card, Modal ,Select} from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import "../styles/css/login.scss";
import { login,getRegisterCode,loginCode,register } from "./api/categories";
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            hospital: '',
            password: '',
            phone: '',
            smsCode: '',
			 timeLeft: 60,
			      isCountingDown: false,
				  email: '',
				  title: '',
				  sex: '',
				  
        };
    }
	validatePhoneNumber=(phone)=> {
	    // 正则表达式：以1开头，后面跟着10个数字
	    const phoneRegex = /^1[3-9]\d{9}$/;
	    return phoneRegex.test(phone);
	}
	startTimer = () => {
	    this.setState({ isCountingDown: true });
	    this.countdown = setInterval(() => {
	      this.setState((prevState) => {
	        if (prevState.timeLeft > 0) {
	          return { timeLeft: prevState.timeLeft - 1 };
	        } else {
	          clearInterval(this.countdown);
	          return { isCountingDown: false };
	        }
	      });
	    }, 1000);
	  }
	getLoginCode = async (phone) => {
		  if (this.validatePhoneNumber(phone)) {
		     
			  if (!this.state.isCountingDown) {
			        this.startTimer();
			       try {
			              // 使用 GET 请求，传递参数
			              const res = await getRegisterCode({
			                  phone: phone,
			              });
			       			
			              console.log(res);
			              if (res.code === "200") {
			                  
			              } else {
			                  alert(res.msg);
			              }
			          } catch (error) {
			              console.error("请求失败:", error);
			              alert("请求失败，请稍后再试");
			          }
			      }
		  } else {
		      alert("手机号输入错误");
			 
		  }
			
			 
	
	  
	};
	// handleSmsLogin = (e) => {
	//     e.preventDefault();
	//     // 在这里可以进行表单验证和提交逻辑
	//     console.log('注册信息:', this.state);
	//     // 例如，调用 API 提交注册信息
	// };
	setCookie =(name, value, iDay)=>{
	      var oDate=new Date();
	      
	      oDate.setDate(oDate.getDate()+iDay);
	      
	      document.cookie=name+'='+value+';expires='+oDate;
	  }
	handleSmsLogin = async (e) => {
	 e.preventDefault();
	     // 在这里可以进行表单验证和提交逻辑
	     console.log('注册信息:', this.state);
	 const { userName, password, phone, smsCode,hospital ,email,title,sex} = this.state;

				try {
				       // 使用 GET 请求，传递参数
				       const res = await register({
				          userName: userName,
				                         password: password,
				                         phone: phone,
				                         code: smsCode,
										 hospital,hospital,
										 email:email,
										 title:title,
										 sex:sex,
										 
				       });
						
				       console.log(res);
				       if (res.code === "200") {
				           sessionStorage.setItem("username", res.data.userName);
				           sessionStorage.setItem("userName", res.data.phone);
				           sessionStorage.setItem("userId", res.data.id);
				           sessionStorage.setItem("bigvideo", 1);
				           sessionStorage.setItem("times", res.data.times);
				           sessionStorage.setItem("activate", res.data.activate);
				           sessionStorage.setItem("token", new Date().getTime());
				           this.setCookie("logostatelocal", 1, 1);
				           this.setCookie("yk", 0);
				           this.props.history.push("/UserLogin");
				       } else {
				           alert(res.msg);
				       }
				   } catch (error) {
				       console.error("请求失败:", error);
				       alert("请求失败，请稍后再试");
				   }
	};
    inputChangeUserName = (e) => {
        this.setState({ userName: e.target.value });
    };

    inputChangeHospital = (e) => {
        this.setState({ hospital: e.target.value });
    };
	inputChangeEmail= (e) => {
        this.setState({ email: e.target.value });
    };
    inputChangePassword = (e) => {
        this.setState({ password: e.target.value });
    };

    inputChangePhoneNumber = (e) => {
        this.setState({ phone: e.target.value });
    };

    inputChangeSmsCode = (e) => {
        this.setState({ smsCode: e.target.value });
    };

  
	 componentWillUnmount() {
	    // 清除定时器以防止内存泄漏
	    if (this.timer) {
	      clearInterval(this.timer);
	    }
	  }
     handleSelectChange = (name, value) => {
        this.setState({ [name]: value });
      };
	
    render() {
		 const { timeLeft, isCountingDown } = this.state;
		    const buttonText = isCountingDown ? `${timeLeft}秒后重试` : '获取验证码';
        const { userName, hospital, password, phone, smsCode } = this.state;

        return (
		<div className="login-main" style={{height: "100vh", overflow: "auto"}}>
		    <div className="login-bg"   style={{height: "100vh", overflow: "auto"}}>
			
		    <div style={{    fontSize: "33px",width:"500px",marginLeft:"5%",textAlign:"center",marginTop:"-100px"}}>
		       
            <Form
                onSubmit={this.handleSmsLogin}
                className="login-form"
                style={{ padding: "5px", marginLeft: "10px" }}
            >
                <h1 className="login-title">注册页面</h1>

                <Form.Item
                    name="userName"
                    rules={[{ required: true, message: "请输入昵称" }]}
                >
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
					<div style={{width:"90px"}}>昵称:</div>	
                    <Input
                        onChange={this.inputChangeUserName}
                        prefix={<Icon type="user" className="login-input-icon" />}
                        placeholder="昵称"
                    /></div>	
                </Form.Item>

                <Form.Item
                    name="hospital"
                    rules={[{ required: true, message: "请输入医院" }]}
                ><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
					<div style={{width:"90px"}}>医院名称:</div>	
                    <Input
                        onChange={this.inputChangeHospital}
                        prefix={<Icon type="home" className="login-input-icon" />}
                        placeholder="医院"
                    /></div>	
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "请输入密码" }]}
                ><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
					<div style={{width:"90px"}}>密码:</div>	
                    <Input
                        type="password"
                        onChange={this.inputChangePassword}
                        prefix={<Icon type="lock" className="login-input-icon" />}
                        placeholder="密码"
                    /></div>	
                </Form.Item>

                
				<Form.Item
				  name="email"
				  rules={[{ required: true, message: "请输入邮箱" }]}
				><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
					<div style={{width:"90px"}}>邮箱:</div>	
				  <Input
				   onChange={this.inputChangeEmail}
				    prefix={<Icon type="text" className="login-input-icon" />}
				    placeholder="邮箱"
				  /></div>	
				</Form.Item>
				<Form.Item
				  name="sex"
				  rules={[{ required: true, message: "请输入邮箱" }]}
				><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
					<div style={{width:"90px"}}>性别:</div>	
					<Select  onChange={(value) => this.handleSelectChange('sex', value)}>
					           <Select.Option value="男">男</Select.Option>
					           <Select.Option value="女">女</Select.Option>
					</Select>	
				  </div>	
				</Form.Item>
				<Form.Item
				  name="title"
				  rules={[{ required: true, message: "请输入邮箱" }]}
				><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
					<div style={{width:"90px"}}>职称:</div>
					<Select  onChange={(value) => this.handleSelectChange('title', value)}>
					           <Select.Option value="医生">医生</Select.Option>
					           <Select.Option value="技师">技师</Select.Option>
					           <Select.Option value="老师">老师</Select.Option>
					           <Select.Option value="学生">学生</Select.Option>
					           <Select.Option value="其他">其他</Select.Option>
					</Select>		
				  </div>	
				</Form.Item>
				<Form.Item
				    name="phoneNumber"
				    rules={[{ required: true, message: "请输入手机号码" }]}
				><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
					<div style={{width:"90px"}}>账号:</div>	
				    <Input
				        onChange={this.inputChangePhoneNumber}
				        prefix={<Icon type="phone" className="login-input-icon" />}
				        placeholder="手机号码"
				    /></div>	
				</Form.Item>
				
				
				
				<Form.Item
				    name="smsCode"
				    rules={[{ required: true, message: "请输入验证码" }]}
				><div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
					<div style={{width:"90px"}}>验证码:</div>	
				    <div style={{ width:"100%" ,marginLeft:"-40px"}}  >
							<Input
							style={{ width:"80%" }}
							    onChange={this.inputChangeSmsCode}
							    prefix={<Icon type="key" className="login-input-icon" />}
							    placeholder="验证码"
							/></div>
								<Button
								        style={{ width: "20%" }}
								        type="primary"
								        className="login-join-button"
								        onClick={() => this.getLoginCode(phone)}
								      >
								        {buttonText}
								      </Button>
					</div>
				</Form.Item>
				<Form.Item
				    name="smsCode"
				    rules={[{ required: true, message: "请输入验证码" }]}
				>
					
				</Form.Item>
				 
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-join-button"
                    >
                        注册
                    </Button>
                </Form.Item>
            </Form>
			</div>
			</div>
			</div>
        );
    }
}

export default Register;
