import React, { Component } from 'react';
import Aliplayer from 'aliyun-aliplayer';
import md5 from 'md5'; // 导入 md5 库
import "../styles/css/VideoPlayer.scss";
class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      // url: props.url, // 初始化 url
      url: this.getZhuanMaUrl(80035,80035), // 初始化 url
      cover: props.cover, // 初始化 cover
    };
    this.playerRef = React.createRef();
  }

  componentDidMount() {
    this.createPlayer(this.state.url, this.state.cover);
	  // 页面加载完成后自动全屏
	    // this.enterFullscreen();
		 setTimeout(() => {
		      this.enterFullscreen();
		    }, 2000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url!== this.props.url || prevProps.cover!== this.props.cover) {
      this.setState({ url: this.props.url, cover: this.props.cover }, () => {
        this.createPlayer(this.state.url, this.state.cover);
      });
    }
  }
getZhuanMaUrl = (appName,streamName) => {
    const liveDomain = "in.xytechsz.com";
    const liveKey = "5S5CYqkC41P564vH";
    const time = Math.floor(Date.now() / 1000) + 86400; // 24小时有效期
    const strviewrtmp1 = `/${appName}/${streamName}-${time}-0-0-${liveKey}`;
    const rtmpurl1 = `artc://${liveDomain}/${appName}/${streamName}?auth_key=${time}-0-0-${md5(strviewrtmp1)}`;
    return rtmpurl1;
  }
  componentWillUnmount() {
    if (this.state.player) {
      this.state.player.destroy();
    }
  }

  createPlayer = (source, cover) => {
    if (this.playerRef.current) {
      this.playerRef.current.dispose();
    }

    this.playerRef.current = new Aliplayer({
      id: 'player-container',
      width: '100%',
      height: "100hv", 
	   autoplay: true,
	       
	       playsinline:true,
    
     
      rePlay: false,
      playsinline: true,
      preload: true,
      controlBarVisibility: 'hover',
      useH5Prism: true,
      videoId: 'your-video-id',
      cover: cover,
      source: source,
	  isLive: true,
    });
  };

  // 添加一个方法来修改视频的 URL
  changeVideoUrl = (newUrl, newCover) => {
    this.setState({ url: newUrl, cover: newCover }, () => {
      this.createPlayer(this.state.url, this.state.cover);
    });
  };
// 处理进入全屏
  enterFullscreen = () => {
    if (this.playerRef.current) {
      if (this.playerRef.current.requestFullscreen) {
        this.playerRef.current.requestFullscreen();
      } else if (this.playerRef.current.mozRequestFullScreen) {
        this.playerRef.current.mozRequestFullScreen();
      } else if (this.playerRef.current.webkitRequestFullscreen) {
        this.playerRef.current.webkitRequestFullscreen();
      } else if (this.playerRef.current.msRequestFullscreen) {
        this.playerRef.current.msRequestFullscreen();
      }
    }
  };
  render() {
    return (
      <div>
        <main  class="video-container">
		   <div id="player-container" className="video-player-container"></div>
      
		    </main>
        <div>聊天框</div>
      </div>
		
     
    );
  }
}

export default VideoPlayer;