import React from "react";
import { Avatar, Modal } from 'antd';
import PenMeeting from "../pen_meeting";
import { reactLocalStorage } from "reactjs-localstorage";
import ResizeObserver from 'resize-observer-polyfill';

//MediaStream对象
let stream;
//画布对象
let canvas;
//画布2D内容
let context;
let drawstate = false;
var spotList = []
let startX = ""
let startY = ""
class BigVideoComponent extends React.Component {

	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();

		this.state = {
			drawState2: true,
			value: 1,
			videoWidth: 0,
			videoHeight: 0,
			marginTop: 0,
			marginLeft: 0,
			displayMainVideo: true,
			aa: 1,
			isDrawing: false,
			isModalOpen: false,
		};

	}
	takeSnap = async (e) => {
		const { id } = this.props;
		console.log(this.refs['canvas2']); // 检查 canvas 是否存在
		//获取画布对象
		let canvas = this.refs['canvas2'];
		//设置画面宽度
		canvas.width = document.getElementById(id).videoWidth;
		//设置画面高度
		canvas.height = document.getElementById(id).videoHeight;
		//根据视频对象,xy坐标,画布宽,画布高绘制位图
		canvas.getContext('2d').drawImage(document.getElementById(id), 0, 0, canvas.width, canvas.height);
		let base64Image = canvas.toDataURL("image/jpeg");
		sessionStorage.setItem("base64Image", base64Image);
	}
	handleshow() {
		this.setState({
			displayMainVideo: true
		})
	}
	handlehide() {
		this.setState({
			displayMainVideo: false
		})
	}

	setValue(msg) {
		console.log(msg)
	}
	setDrawState = (event) => {
		drawstate = !drawstate
	}

	componentDidMount() {
		// this.updateCanvasListeners();
		this.drawCircle(); // 组件挂载后绘制圆圈

		const { id } = this.props;

		var that = this
		document.getElementById(id).oncanplay = function () {

			var videoWidth = 0
			var videoHeight = 0
			var vclientWidth = document.getElementById(id).clientWidth
			var vclientHeight = document.getElementById(id).clientHeight

			// 触发canplay事件之后获取video的宽高为640,480
			videoWidth = document.getElementById(id).videoWidth
			videoHeight = document.getElementById(id).videoHeight

			if (videoWidth / videoHeight >= vclientWidth / vclientHeight) {
				document.getElementById("canvasshow").setAttribute("width", vclientWidth)
				var vtheight = vclientWidth * videoHeight / videoWidth
				document.getElementById("canvasshow").setAttribute("height", vtheight)
				document.getElementById("canvasshow").style.marginTop = (vclientHeight - vtheight) / 2 + "px"
				that.setState({
					videoWidth: videoWidth,
					videoHeight: videoHeight,
					marginTop: (vclientHeight - vtheight) / 2
				})
				document.getElementById("canvasshow").style.marginLeft = "0px"
			} else {

				document.getElementById("canvasshow").setAttribute("height", vclientHeight)

				var vtwidth = vclientHeight * videoWidth / videoHeight

				document.getElementById("canvasshow").setAttribute("width", vtwidth)
				document.getElementById("canvasshow").style.marginLeft = (vclientWidth - vtwidth) / 2 + "px"
				document.getElementById("canvasshow").style.marginTop = "0px"
				that.setState({
					videoWidth: videoWidth,
					videoHeight: videoHeight,
					marginLeft: (vclientWidth - vtwidth) / 2
				})
			}





		}


		canvas = this.refs['canvas'];


		const { stream } = this.props;
		this.video.srcObject = stream;

		const ro = new ResizeObserver((entries, observer) => {
			try {
				for (const entry of entries) {
					let canvassowDo = document.getElementById("canvasshow");
					let content = canvassowDo.getContext("2d")

					const { left, top, width, height } = entry.contentRect;
					let data = content.getImageData(0, 0, width, height)

					if (that.state.videoWidth / that.state.videoHeight >= document.getElementById(id).clientWidth / document.getElementById(id).clientHeight) {
						canvassowDo.setAttribute("width", document.getElementById(id).clientWidth)

						let vtheight = document.getElementById(id).clientWidth * that.state.videoHeight / that.state.videoWidth
						canvassowDo.setAttribute("height", vtheight)
						canvassowDo.style.marginTop = (document.getElementById(id).clientHeight - vtheight) / 2 + "px"
						canvassowDo.style.marginLeft = "0px"
						that.setState({
							marginTop: (document.getElementById(id).clientHeight - vtheight) / 2
						})
					} else {
						canvassowDo.setAttribute("height", document.getElementById(id).clientHeight)

						let vtwidth = document.getElementById(id).clientHeight * that.state.videoWidth / that.state.videoHeight
						canvassowDo.setAttribute("width", vtwidth)
						canvassowDo.style.marginLeft = (document.getElementById(id).clientWidth - vtwidth) / 2 + "px"
						canvassowDo.style.marginTop = "0px"
						that.setState({
							marginLeft: (document.getElementById(id).clientWidth - vtwidth) / 2
						})



					}


					content.putImageData(data, 0, 0)

				}
			} catch (error) {
				console.log(error)
			}
		});
		ro.observe(document.getElementById(id));


	}


	//开始捕获Canvas
	startCaptureCanvas = async (e) => {


		this.drawLine();

	}
	safeDivide = (multipleNew, multipleOld) => {
		// 尝试将输入值转换为整数
		const num1 = parseInt(multipleNew, 10);
		const num2 = parseInt(multipleOld, 10);

		// 检查转换后的值是否为有效的整数
		if (isNaN(num1) || isNaN(num2)) {
			throw new Error("Both inputs must be convertible to integers.");
		}

		// 检查除数是否为零
		if (num2 === 0) {
			throw new Error("The divisor cannot be zero.");
		}

		// 执行除法运算
		return num1 / num2;
	}
	drawLineRuler2 = () => {
		const { id } = this.props;

		const context = canvas.getContext("2d");

		// 获取画布宽高
		const width = canvas.clientWidth;
		const height = canvas.clientHeight;

		// 设置透明度、填充样式和线条颜色
		let setBrightness = reactLocalStorage.get("setBrightness") ? reactLocalStorage.get("setBrightness") : 90
		context.globalAlpha = setBrightness / 100; // 0.0 (完全透明) 到 1.0 (完全不透明)
		context.fillStyle = reactLocalStorage.get("color") ? reactLocalStorage.get("color") : "red";
		context.strokeStyle = reactLocalStorage.get("color") ? reactLocalStorage.get("color") : "red";
		context.lineWidth = 3; // 较细的线条用于绘制标尺

		// 计算中心点
		const centerX = width / 2;
		const centerY = height / 2;

		// 清除画布
		context.clearRect(0, 0, width, height);

		let smallGridSize = reactLocalStorage.get("dingbiao");


		let multipleNew = reactLocalStorage.get("multiple") || 20
		let multipleOld = reactLocalStorage.get("dingbiaoMultiple") || 20
		let multiple1 = this.safeDivide(multipleNew, multipleOld)

		smallGridSize = smallGridSize * multiple1

		smallGridSize = parseFloat(Number(smallGridSize).toFixed(10)); // 确保最终结果是数字
		console.log(smallGridSize);
		// 绘制水平标尺
		context.beginPath();
		for (let x = 0; x <= width; x += smallGridSize) {
			const gridIndex = Math.round(x / smallGridSize); // 使用四舍五入避免浮点误差
			if (gridIndex % 10 === 0) {
				// 每隔10个小格（即41.6像素）绘制最长的刻度
				context.moveTo(x, centerY - 150);
				context.lineTo(x, centerY + 150);
			} else if (gridIndex % 5 === 0) {
				// 每隔5个小格（即20.8像素）绘制中等长度的刻度
				context.moveTo(x, centerY - 100);
				context.lineTo(x, centerY + 100);
			} else {
				// 每个小格（即4.16像素）绘制较短的刻度
				context.moveTo(x, centerY - 50);
				context.lineTo(x, centerY + 50);
			}
		}
		context.stroke();

		// 绘制垂直标尺
		context.beginPath();
		for (let y = 0; y <= height; y += smallGridSize) {
			const gridIndex = Math.round(y / smallGridSize); // 使用四舍五入避免浮点误差
			if (gridIndex % 10 === 0) {
				// 每隔10个小格（即41.6像素）绘制最长的刻度
				context.moveTo(centerX - 150, y);
				context.lineTo(centerX + 150, y);
			} else if (gridIndex % 5 === 0) {
				// 每隔5个小格（即20.8像素）绘制中等长度的刻度
				context.moveTo(centerX - 100, y);
				context.lineTo(centerX + 100, y);
			} else {
				// 每个小格（即4.16像素）绘制较短的刻度
				context.moveTo(centerX - 50, y);
				context.lineTo(centerX + 50, y);
			}
		}
		context.stroke();

		// 设置字体和颜色
		context.font = '16px Arial'; // 字体大小和类型
		context.fillStyle = '#000'; // 文本颜色

		// 在画布上绘制文本 "高30mm"
		// context.fillText('高30mm', centerX + 160, centerY + 180); // 调整坐标以放置文本
	}
	drawLine0 = () => {


		// 画箭头
		const { id } = this.props;
		console.log(document.getElementById(id).clientWidth)
		console.log(document.getElementById(id).clientHeight)



		document.getElementById("canvasshow").setAttribute("topmargin", 200)
		context = canvas.getContext("2d");

		context.lineWidth = 20;

		// 设置透明度
		let setBrightness = reactLocalStorage.get("setBrightness") ? reactLocalStorage.get("setBrightness") : 90
		context.globalAlpha = setBrightness / 100; // 0.0 (完全透明) 到 1.0 (完全不透明)
		context.fillStyle = reactLocalStorage.get("color") ? reactLocalStorage.get("color") : "red";
		context.strokeStyle = reactLocalStorage.get("color") ? reactLocalStorage.get("color") : "red";
		// 计算中心点
		const centerX = canvas.width / 2;
		const centerY = canvas.height / 2;

		// 开始绘制倒 V
		context.beginPath();

		// 开始绘制第一条线段（从起点到第一个终点）

		context.moveTo(centerX - 50, centerY + 50);
		context.lineTo(centerX, centerY - 50);

		context.lineTo(centerX + 50, centerY + 50);
		context.stroke();
	}
	//定标绑定鼠标按下事件
	calibrateMousemove = (event) => {

		if (!this.state.isDrawing) return;

		// 清除之前的内容
		context.clearRect(0, 0, canvas.width, canvas.height);

		const rect = canvas.getBoundingClientRect();
		const endX = event.clientX - rect.left;
		const endY = event.clientY - rect.top;
		const calculateDistance = (startX, startY, endX, endY) => {
			const deltaX = endX - startX;
			const deltaY = endY - startY;

			return Math.sqrt(deltaX * deltaX + deltaY * deltaY).toFixed(10);
		};

		// 使用示例
		const distance = calculateDistance(startX, startY, endX, endY);
		
		const tendb = distance / 50

		// 绘制当前的直线
		context.beginPath();
		context.moveTo(startX, startY);
		context.lineTo(endX, endY);

		reactLocalStorage.set("dingbiao", tendb)
		reactLocalStorage.set("dingbiaoMultiple", reactLocalStorage.get("multiple"))
		context.stroke();
		// 设置字体和颜色
		context.font = '16px Arial'; // 字体大小和类型
		context.fillStyle = '#ff0000'; // 文本颜色

		// 在画布上绘制文本 "高30mm"
		const textPositionX = (startX + endX) / 2; // 文本放置在直线中间
		const textPositionY = (startY + endY) / 2 - 10; // 调整 Y 坐标以避免覆盖线条
		let longUM = 10 * distance / tendb
		context.fillText(500 + 'um', textPositionX, textPositionY);

	}
	calibrateMousedown = (event) => {

		this.setState({ isDrawing: true })

		const rect = canvas.getBoundingClientRect();

		startX = event.clientX - rect.left;
		startY = event.clientY - rect.top;

	}
	calibrateMouseup = (event) => {

		this.setState({ isDrawing: false,isModalOpen:true })
		
		// canvas.removeEventListener("mousedown", this.calibrateMousedown);
		// canvas.removeEventListener("mousemove", this.calibrateMousemove);
		// canvas.removeEventListener("mouseup", this.calibrateMouseup);

	}
	//定标
	drawLineRuler = (msg) => {

		canvas.removeEventListener("mousedown", this.measureMousedown);
		canvas.removeEventListener("mousemove", this.measureMousemove);
		canvas.removeEventListener("mouseup", this.measureMouseup);
		this.setState((state) => ({ drawState2: !state.drawState2 }));
		console.log(this.state.drawState2)

		const { id } = this.props;

		context = canvas.getContext("2d");

		// 设置线条样式
		context.strokeStyle = reactLocalStorage.get("color") || 'red';
		context.lineWidth = 4;

		let isDrawing = false;
		let startX, startY;

		// 鼠标按下事件：记录起始点并开始绘制
		canvas.addEventListener('mousedown', this.calibrateMousedown);

		// 鼠标移动事件：如果正在绘制，则更新终点并重绘
		canvas.addEventListener('mousemove', this.calibrateMousemove);

		// 鼠标松开事件：结束绘制
		canvas.addEventListener('mouseup', this.calibrateMouseup);


	}

	//定标绑定鼠标按下事件
	measureMousemove = (event) => {
		if (!this.state.isDrawing) return;
		// 清除之前的内容
		context.clearRect(0, 0, canvas.width, canvas.height);

		const rect = canvas.getBoundingClientRect();
		const endX = event.clientX - rect.left;
		const endY = event.clientY - rect.top;
		const calculateDistance = (startX, startY, endX, endY) => {
			const deltaX = endX - startX;
			const deltaY = endY - startY;

			return Math.sqrt(deltaX * deltaX + deltaY * deltaY).toFixed(10);
		};

		// 使用示例
		const distance = calculateDistance(startX, startY, endX, endY);


		// 绘制当前的直线
		context.beginPath();
		context.moveTo(startX, startY);
		context.lineTo(endX, endY);


		context.stroke();
		// 设置字体和颜色
		context.font = '16px Arial'; // 字体大小和类型
		context.fillStyle = '#ff0000'; // 文本颜色

		// 在画布上绘制文本 "高30mm"
		const textPositionX = (startX + endX) / 2; // 文本放置在直线中间
		const textPositionY = (startY + endY) / 2 - 10; // 调整 Y 坐标以避免覆盖线条
		let longUM = 10 * distance / reactLocalStorage.get("dingbiao")
		let multipleNew = reactLocalStorage.get("multiple") || 20
		let multipleOld = reactLocalStorage.get("dingbiaoMultiple") || 20
		let multiple1 = this.safeDivide(multipleNew, multipleOld)
		let multipleProduct=multiple1*multiple1
		let longUMNew = longUM/multiple1
		context.fillText(Math.round(longUMNew) + 'um', textPositionX, textPositionY);

	}
	measureMousedown = (event) => {

		this.setState({ isDrawing: true })

		const rect = canvas.getBoundingClientRect();

		startX = event.clientX - rect.left;
		startY = event.clientY - rect.top;

	}
	measureMouseup = (event) => {

		this.setState({ isDrawing: false })



	}
	//测量
	drawMeasure = (msg) => {
		canvas.removeEventListener("mousedown", this.calibrateMousedown);
		canvas.removeEventListener("mousemove", this.calibrateMousemove);
		canvas.removeEventListener("mouseup", this.calibrateMouseup);
		this.setState((state) => ({ drawState2: !state.drawState2 }));
		context = canvas.getContext("2d");

		// 设置线条样式
		context.strokeStyle = reactLocalStorage.get("color") || 'red';
		context.lineWidth = 4;



		// 鼠标按下事件：记录起始点并开始绘制
		canvas.addEventListener('mousedown', this.measureMousedown);

		// 鼠标移动事件：如果正在绘制，则更新终点并重绘
		canvas.addEventListener('mousemove', this.measureMousemove);

		// 鼠标松开事件：结束绘制
		canvas.addEventListener('mouseup', () => {
			this.setState({ isDrawing: false })
		});

	}



	removeAllCanvasListeners = () => {

		canvas.removeEventListener("mousedown", this.handleMouseDown);
		canvas.removeEventListener("mousemove", this.handleMouseMove);
		canvas.removeEventListener("mouseup", this.handleMouseUp);
		canvas.removeEventListener("touchstart", this.startActionPhone);
		canvas.removeEventListener("touchend", this.endActionPhone);
	};
	removeAllEventListeners = (canvas) => {
		['click', 'mousedown', 'mouseup', 'touchstart', 'touchend'].forEach(event => {
			const handler = canvas[`${event}Handler`]; // 使用自定义属性来获取处理器引用
			if (handler) {
				canvas.removeEventListener(event, handler);
				delete canvas[`${event}Handler`]; // 清除引用以避免内存泄漏
			}
		});
	}
	drawLine = () => {
		const { id } = this.props;
		canvas.removeEventListener("mousedown", this.calibrateMousedown);
		canvas.removeEventListener("mousemove", this.calibrateMousemove);
		canvas.removeEventListener("mouseup", this.calibrateMouseup);
		canvas.removeEventListener("mousedown", this.measureMousedown);
		canvas.removeEventListener("mousemove", this.measureMousemove);
		canvas.removeEventListener("mouseup", this.measureMouseup);

		console.log(document.getElementById(id).clientWidth)
		console.log(document.getElementById(id).clientHeight)
		this.removeAllCanvasListeners(canvas)


		document.getElementById("canvasshow").setAttribute("topmargin", 200)
		context = canvas.getContext("2d");

		context.lineWidth = sessionStorage.getItem("pensize") ? sessionStorage.getItem("pensize") : 2;
		// context.lineWidth =82;
		context.strokeStyle = reactLocalStorage.get("color") ? reactLocalStorage.get("color") : "red";
		// 0.0 (完全透明) 到 1.0 (完全不透明)
		//监听画板鼠标按下事件 开始绘画
		console.log(this.state.drawState2 == true)
		// 先移除所有事件监听器以避免冲突
		this.removeAllCanvasListeners();
		canvas.addEventListener("mousedown", this.startAction);
		//手机端按下
		canvas.addEventListener("touchstart", this.startActionPhone)
		//监听画板鼠标抬起事件 结束绘画
		canvas.addEventListener("mouseup", this.endAction);
		//手机端移走手指  监听画板鼠标抬起事件 结束绘画
		canvas.addEventListener("touchend", this.endActionPhone);
		if (this.state.drawState2 == true) {
			//画自由线
		} else {

			//画直线

		}



	}
	clearCanvas2 = (event) => {
		context = canvas.getContext("2d");
		context.clearRect(0, 0, canvas.width, canvas.height);
	}
	clearCanvas = (event) => {
		this.props.onSendMessage(JSON.stringify({
			type: "clearCanvas",
			data: spotList
		}));
		spotList.length = 0;
		context = canvas.getContext("2d");
		context.clearRect(0, 0, canvas.width, canvas.height);

		// this.drawLine0();
	}

	drawCanvas = (event, colorType, pensize) => {
		const { id } = this.props;
		let spotObjDraw = JSON.parse(event)
		// displayWidth
		var kjh = document.getElementById("canvasshow").clientHeight
		var kjw = document.getElementById("canvasshow").clientWidth

		var kjhClientHeight = spotObjDraw.clientHeight
		var kjwClientWidth = spotObjDraw.clientWidth

		var videoStateHeight = this.state.videoHeight
		var videoStateWidth = this.state.videoWidth

		var h0 = kjh / kjhClientHeight
		var w0 = kjw / kjwClientWidth

		// intrinsicWidth
		var zsh = document.getElementById(id).clientHeight;
		var zsw = document.getElementById(id).clientWidth;


		var xx1 = (event.offsetX - (kjw - zsw * kjh / zsh) / 2) * zsh / kjh
		var yy1 = (event.offsetY - (kjh - zsh * kjw / zsw) / 2) * zsw / kjw


		if (spotObjDraw.data.length > 0) {
			context.beginPath();
			context.moveTo(spotObjDraw.data[0].x * kjw / kjwClientWidth, spotObjDraw.data[0].y * kjh / kjhClientHeight)
			// context.moveTo(spotObjDraw.data[0].x,spotObjDraw.data[0].y)
			for (let i = 1; i < spotObjDraw.data.length; i++) {
				context.lineTo(spotObjDraw.data[i].x * kjw / kjwClientWidth, spotObjDraw.data[i].y * kjh / kjhClientHeight)
				// context.lineTo(spotObjDraw.data[i].x,spotObjDraw.data[i].y)
				// context.lineTo(spotObjDraw.data[i].x,spotObjDraw.data[i].y)
			}
			context.strokeStyle = colorType;
			context.lineWidth = pensize ? pensize : 2;

			context.stroke();

		}

	}
	//鼠标按下事件


	startActionPhone = (event) => {

		console.log(event)

		context.lineWidth = sessionStorage.getItem("pensize") ? sessionStorage.getItem("pensize") : 2;
		// context.lineWidth =82;
		context.strokeStyle = reactLocalStorage.get("color") ? reactLocalStorage.get("color") : "red";
		// 0.0 (完全透明) 到 1.0 (完全不透明)
		const { id } = this.props;
		// displayWidth
		var kjh = document.getElementById("canvasshow").clientHeight
		var kjw = document.getElementById("canvasshow").clientWidth

		// intrinsicWidth
		var zsh = document.getElementById(id).clientHeight;
		var zsw = document.getElementById(id).clientWidth;


		// var xx1=(event.changedTouches[0].clientX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.changedTouches[0].clientY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		var computedStyle = getComputedStyle(document.getElementById("canvasshow"), null);

		const container = document.getElementById("container");
		const containerRect = container.getBoundingClientRect();

		const canvasRect = canvas.getBoundingClientRect();
		const scaleX = canvas.width / canvasRect.width;
		const scaleY = canvas.height / canvasRect.height;


		let xx1 = 0;
		let yy1 = 0;
		if (this.state.videoWidth / this.state.videoHeight >= document.getElementById(id).clientWidth / document.getElementById(id).clientHeight) {
			xx1 = (event.touches[0].clientX - containerRect.left) * scaleX;
			yy1 = (event.touches[0].clientY - this.state.marginTop) * scaleY;
		} else {
			xx1 = (event.touches[0].clientX - this.state.marginLeft) * scaleX;
			yy1 = (event.touches[0].clientY - containerRect.top) * scaleY;
		}
		// const xx1 = (event.touches[0].clientX*this.state.videoWidth/kjw - containerRect.left) * scaleX;
		// const yy1 = (event.touches[0].clientY*this.state.videoHeight/kjh - containerRect.top) * scaleY;
		// var xx1=event.changedTouches[0].clientX*this.state.videoWidth/kjw
		// var yy1=event.changedTouches[0].clientY*this.state.videoHeight/kjh-this.state.marginTop
		console.log(computedStyle.marginTop)
		console.log(this.state.marginTop)
		console.log(this.state.marginTop)
		console.log(event.changedTouches[0].clientX + "---" + event.changedTouches[0].clientY)
		// console.log(xx1+"---"+yy1)
		console.log(xx1 + "---" + yy1)
		//开始新的路径
		context.beginPath();
		//将画笔移动到指定坐标，类似起点

		spotList.push({
			x: xx1 * this.state.videoWidth / kjw,
			y: yy1 * this.state.videoHeight / kjh
		})
		// context.moveTo(event.changedTouches[0].clientX,event.changedTouches[0].clientY)
		context.moveTo(xx1, yy1)
		// context.moveTo(event.offsetX*(5/16), event.offsetY*(8/45));
		//开始绘制
		context.stroke();
		//手机端  移动手指
		canvas.addEventListener("touchmove", this.moveActionPhone);


	}
	startAction = (event) => {
		console.log("ssss")
		console.log(event)
		context.lineWidth = sessionStorage.getItem("pensize") ? sessionStorage.getItem("pensize") : 2;
		// context.lineWidth =82;
		context.strokeStyle = reactLocalStorage.get("color") ? reactLocalStorage.get("color") : "red";
		// 0.0 (完全透明) 到 1.0 (完全不透明)
		const { id } = this.props;
		// displayWidth
		var kjh = document.getElementById("canvasshow").clientHeight
		var kjw = document.getElementById("canvasshow").clientWidth

		// intrinsicWidth
		var zsh = document.getElementById(id).clientHeight;
		var zsw = document.getElementById(id).clientWidth;


		// var xx1=(event.offsetX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.offsetY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		// var xx1=event.offsetX*kjw/this.state.videoWidth
		// var yy1=event.offsetY*kjh/this.state.videoHeight
		var xx1 = event.offsetX * this.state.videoWidth / kjw
		var yy1 = event.offsetY * this.state.videoHeight / kjh

		//开始新的路径
		context.beginPath();

		//将画笔移动到指定坐标，类似起点
		spotList.push({
			x: xx1,
			y: yy1
		})
		context.moveTo(event.offsetX, event.offsetY)
		// context.moveTo(event.offsetX*(5/16), event.offsetY*(8/45));
		//开始绘制
		context.stroke();
		//监听鼠标移动事件  
		canvas.addEventListener("mousemove", this.moveAction);



	}

	//鼠标移动事件  
	moveAction = (event) => {

		const { id } = this.props;
		// displayWidth
		var kjh = document.getElementById("canvasshow").clientHeight
		var kjw = document.getElementById("canvasshow").clientWidth

		// intrinsicWidth
		var zsh = document.getElementById(id).clientHeight;
		var zsw = document.getElementById(id).clientWidth;


		// var xx1=(event.offsetX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.offsetY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		// var xx1=event.offsetX*kjw/this.state.videoWidth
		// var yy1=event.offsetY*kjh/this.state.videoHeight
		var xx1 = event.offsetX * this.state.videoWidth / kjw
		var yy1 = event.offsetY * this.state.videoHeight / kjh
		context.lineTo(event.offsetX, event.offsetY)
		spotList.push({
			x: xx1,
			y: yy1
		})
		//将画笔移动到结束坐标，类似终点
		// context.lineTo(event.offsetX*(5/16), event.offsetY*(8/45));
		// console.log(event.offsetX, event.offsetY*(8/45))

		//开始绘制
		// 0.0 (完全透明) 到 1.0 (完全不透明)
		context.stroke();
	}

	moveActionPhone = (event) => {

		const { id } = this.props;
		// displayWidth
		var kjh = document.getElementById("canvasshow").clientHeight
		var kjw = document.getElementById("canvasshow").clientWidth

		// intrinsicWidth
		var zsh = document.getElementById(id).clientHeight;
		var zsw = document.getElementById(id).clientWidth;


		// var xx1=(event.changedTouches[0].clientX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.changedTouches[0].clientY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		const container = document.getElementById("container");
		const containerRect = container.getBoundingClientRect();
		let canvas = document.getElementById("canvasshow")
		const canvasRect = canvas.getBoundingClientRect();
		const scaleX = canvas.width / canvasRect.width;
		const scaleY = canvas.height / canvasRect.height;
		let xx1 = 0;
		let yy1 = 0;
		if (this.state.videoWidth / this.state.videoHeight >= document.getElementById(id).clientWidth / document.getElementById(id).clientHeight) {
			xx1 = (event.touches[0].clientX - containerRect.left) * scaleX;
			yy1 = (event.touches[0].clientY - this.state.marginTop) * scaleY;
		} else {
			xx1 = (event.touches[0].clientX - this.state.marginLeft) * scaleX;
			yy1 = (event.touches[0].clientY - containerRect.top) * scaleY;
		}
		// const xx1 = (event.touches[0].clientX*this.state.videoWidth/kjw - containerRect.left) * scaleX;
		// const yy1 = (event.touches[0].clientY*this.state.videoHeight/kjh - containerRect.top) * scaleY;
		// var xx1=event.changedTouches[0].clientX*this.state.videoWidth/kjw
		// var yy1=event.changedTouches[0].clientY*this.state.videoHeight/kjh-this.state.marginTop


		context.lineTo(xx1, yy1)
		// context.lineTo(event.changedTouches[0].clientX,event.changedTouches[0].clientY)
		spotList.push({
			x: xx1 * this.state.videoWidth / kjw,
			y: yy1 * this.state.videoHeight / kjh
		})
		//将画笔移动到结束坐标，类似终点
		// context.lineTo(event.offsetX*(5/16), event.offsetY*(8/45));
		// console.log(event.offsetX, event.offsetY*(8/45))

		//开始绘制
		context.stroke();
	}
	//鼠标抬起事件 
	endActionPhone = () => {
		console.log(spotList)
		this.props.onSendMessage(JSON.stringify({
			type: "drawLine",
			data: spotList,
			clientHeight: this.state.videoHeight,
			clientWidth: this.state.videoWidth
		}));
		spotList.length = 0;
		//移除鼠标移动事件
		canvas.removeEventListener("touchend", this.moveActionPhone);


	}
	endAction = () => {
		console.log(this.state.videoHeight)
		console.log(this.state.videoWidth)
		this.props.onSendMessage(JSON.stringify({
			type: "drawLine",
			data: spotList,
			clientHeight: this.state.videoHeight,
			clientWidth: this.state.videoWidth
		}));
		spotList.length = 0;
		//移除鼠标移动事件
		canvas.removeEventListener("mousemove", this.moveAction);


	}
	updateCanvasListeners = () => {
		console.log("sss1122323")
		const canvas = this.canvasRef.current;
		const context = canvas.getContext("2d");

		// 清除所有事件监听器
		canvas.removeEventListener("mousedown", this.startAction);
		canvas.removeEventListener("touchstart", this.startActionPhone);
		canvas.removeEventListener("mouseup", this.endAction);
		canvas.removeEventListener("touchend", this.endActionPhone);

		if (this.state.drawState2) {
			// 添加自由绘制的事件监听器
			canvas.addEventListener("mousedown", this.startAction);
			canvas.addEventListener("touchstart", this.startActionPhone);
			canvas.addEventListener("mouseup", this.endAction);
			canvas.addEventListener("touchend", this.endActionPhone);
		} else {
			// 设置直线绘制的事件监听器
			let isDrawing = false;
			let startX, startY;

			const handleMouseDown = (event) => {
				isDrawing = true;
				const rect = canvas.getBoundingClientRect();
				startX = event.clientX - rect.left;
				startY = event.clientY - rect.top;
			};

			const handleMouseMove = (event) => {
				if (!isDrawing) return;
				context.clearRect(0, 0, canvas.width, canvas.height);
				const rect = canvas.getBoundingClientRect();
				const endX = event.clientX - rect.left;
				const endY = event.clientY - rect.top;
				context.beginPath();
				context.moveTo(startX, startY);
				context.lineTo(endX, endY);
				context.stroke();
			};

			const handleMouseUp = () => {
				isDrawing = false;
			};

			canvas.addEventListener('mousedown', handleMouseDown);
			canvas.addEventListener('mousemove', handleMouseMove);
			canvas.addEventListener('mouseup', handleMouseUp);
		}
	};

	componentDidUpdate(prevProps, prevState) {

		const { MirrorOnline, DesktopOnline, } = this.props;
		if (prevProps.MirrorOnline !== MirrorOnline) {
			this.drawCircle();
		}
		if (prevProps.DesktopOnline !== DesktopOnline) {
			this.drawCircle();
		}

	}
	changeColor = () => {

		const { name } = this.props;
		const name00 = sessionStorage.getItem("userName")
		if (name === null || name === undefined || name === "") {

		} else if (name === name00) {

			this.props.MirrorOnlineClick()

		} else if (name === name00 + "分享屏幕") {

			this.props.DesktopOnlineClick()
		} else {

		}
	}
	drawCircle = () => {

		const canvas = this.canvasRef.current;
		const context = canvas.getContext('2d');
		// 清空画布
		context.clearRect(0, 0, canvas.width, canvas.height);
		const { MirrorOnline, DesktopOnline, name } = this.props;
		let setBrightness = reactLocalStorage.get("setBrightness") ? reactLocalStorage.get("setBrightness") : 90
		context.globalAlpha = setBrightness / 100; // 0.0 (完全透明) 到 1.0 (完全不透明)
		const name00 = sessionStorage.getItem("userName")
		if (name === null || name === undefined || name === "") {
			// 延迟 3 秒后重新判断
			setTimeout(this.drawCircle, 1000);
		} else if (name === name00) {
			context.fillStyle = MirrorOnline ? 'green' : 'red';
		} else if (name === name00 + "分享屏幕") {
			context.fillStyle = DesktopOnline ? 'green' : 'red';
		} else {
			context.fillStyle = 'green';
		}
		context.beginPath();

		context.arc(20, 20, 10, 0, Math.PI * 2); // 圆心在 (10, 10)，半径为 5
		context.fill();
		context.stroke(); // 可选：绘制边框
	}




	componentWillUnmount = () => {
		this.video.srcObject = null;
	}
	handleOk = () => {
		this.setState({ isModalOpen: false });
		canvas.removeEventListener("mousedown", this.calibrateMousedown);
		canvas.removeEventListener("mousemove", this.calibrateMousemove);
		canvas.removeEventListener("mouseup", this.calibrateMouseup);
		canvas.removeEventListener("mousedown", this.measureMousedown);
		canvas.removeEventListener("mousemove", this.measureMousemove);
		canvas.removeEventListener("mouseup", this.measureMouseup);
	}
	handleCancel = () => {
		this.setState({ isModalOpen: false });
	}

	render = () => {
		const { id, vidFit, name, muted, hostStatelocal, MirrorOnline, DesktopOnline, isModalOpen } = this.props;
		const fitClass = vidFit ? "fit-vid" : "";

		return (
			<div className={this.state.displayMainVideo ? "main-video-layout" : "main-video-layout2"} >
				
				<div className="small-canvas" id="container">
					{/* Canvas不设置样式 */}
					<video
						ref={ref => {
							this.video = ref;
						}}
						muted={hostStatelocal}
						id={id}
						autoPlay
						playsInline
						className={"resizebox  main-video-size " + fitClass}
					/>
					<Modal
						
						title="是否结束定标"
						visible={this.state.isModalOpen}
						okText="确定"
						cancelText="取消"
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						style={{ top: 0 }} // 将 Modal 移动到页面顶部
					>
						<p><h1>定标参数:</h1></p>
						<p>在{reactLocalStorage.get("dingbiaoMultiple")}倍物镜下定标</p>
						<p>定标长度500um</p>
					</Modal>
					<canvas ref='canvas' id="canvasshow"></canvas>
					<canvas ref='canvas1' id="canvasshow1"></canvas>
					<canvas ref='canvas2' id="canvasshow2" style={{ display: 'none' }}></canvas>
					<a className="main-video-name-a" onClick={this.changeColor} >{name} <canvas ref={this.canvasRef} width="40" height="40"></canvas></a>
				</div>



			</div>
		)
	}
}

export default BigVideoComponent;
